<template>
  <div>
    <b-row>
      <!-- about  -->
      <b-col lg="4" md="6" cols="12" class="pt-2">
        <b-card
          :disabled="!hasInterview || loading"
          :class="{
            'disabled-card': !hasInterview || loading,
            clickable: hasInterview,
          }"
          class="p-1 clickable"
          @click.prevent="
            hasInterview ? getCustomerFinancialDiagnosisToken() : null
          "
        >
          <b-row class="justify-content-center">
            <div class="rounded-icon">
              <feather-icon icon="SearchIcon" size="28" class="text-info" />
            </div>
          </b-row>
          <b-row class="justify-content-center mt-2">
            <h4 class="mb-75 text-center">Diagnóstico financeiro</h4>
          </b-row>
          <b-row class="justify-content-center">
            <span class="text-center">
              {{
                loading
                  ? "Aguarde..."
                  : hasInterview
                  ? "Acesse o diagnóstico financeiro do seu cliente aqui!"
                  : "O diagnóstico financeiro ficará disponível após envio da pré-entrevista."
              }}
            </span>
          </b-row>
          <b-row class="justify-content-center mt-2">
            <div class="border-line"></div>
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="4" md="6" cols="12" class="pt-2">
        <b-card
          :disabled="!hasInterview || loading"
          :class="{
            'disabled-card': !hasInterview || loading,
            clickable: hasInterview,
          }"
          class="p-1 clickable"
          @click.prevent="hasInterview ? goToFinancialPlanning() : null"
        >
          <b-row class="justify-content-center">
            <div class="rounded-icon">
              <feather-icon icon="DollarSignIcon" size="28" class="text-info" />
            </div>
          </b-row>
          <b-row class="justify-content-center mt-2">
            <h4 class="mb-75 text-center">Momento X</h4>
          </b-row>
          <b-row class="justify-content-center">
            <span class="text-center">
              {{
                loading
                  ? "Aguarde..."
                  : hasInterview
                  ? "Acesse o Momento X e plano financeiro do seu cliente aqui!"
                  : "O Momento X e plano financeiro ficarão disponíveis após envio da pré-entrevista."
              }}
            </span>
          </b-row>
          <b-row class="justify-content-center mt-2">
            <div class="border-line"></div>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        lg="4"
        md="6"
        cols="12"
        class="pt-2"
        v-if="customerType === profileType.PROFILE_TYPE_CUSTOMER"
      >
        <b-card
          :disabled="loading"
          class="p-1 clickable"
          @click.prevent="getCustomerCashFlowToken"
        >
          <b-row class="justify-content-center">
            <div class="rounded-icon">
              <feather-icon icon="SettingsIcon" size="28" class="text-info" />
            </div>
          </b-row>
          <b-row class="justify-content-center mt-2">
            <h4 class="mb-75">App Meu Futuro</h4>
          </b-row>
          <b-row class="justify-content-center">
            <span class="text-center">{{
              loading
                ? "Aguarde..."
                : "Acesse as ferramentas financeiras do seu cliente aqui!"
            }}</span>
          </b-row>
          <b-row class="justify-content-center mt-2">
            <div class="border-line"></div>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import { useToast } from "vue-toastification/composition";
import { mapActions } from "vuex";
import { BCard, BCardText, BRow, BCol } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import * as profileTypes from "@/constants/profile";
import * as types from "../store/types";
export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
  },
  data() {
    return {
      loading: false,
      cashFlowFrontEndUrl: process.env.VUE_APP_CASH_FLOW_FRONTEND_URL,
      financialDiagnosisUrl:
        process.env.VUE_APP_FINANCIAL_DIAGNOSIS_FRONTEND_URL,
    };
  },
  props: {
    customer: {
      type: Object,
    },
    customerType: String,
  },
  setup() {
    const { skin } = useAppConfig();
    return { toast: useToast(), skin };
  },
  mounted() {},
  computed: {
    profileType: function () {
      return profileTypes;
    },
    hasInterview: function () {
      return this.customer?.interviews?.length;
    },
  },
  methods: {
    ...mapActions({
      getToken: types.GET_CUSTOMER_CASH_FLOW_TOKEN,
      getFinancialDiagnosisToken: types.GET_CUSTOMER_FINANCIAL_DIAGNOSIS_TOKEN,
    }),
    getCustomerCashFlowToken() {
      this.loading = true;
      this.getToken(this.$route.params.id)
        .then((response) => {
          setTimeout(() => {
            window
              .open(
                this.cashFlowFrontEndUrl +
                  "/autenticacao/" +
                  response.data?.token,
                "_blank"
              )
              .focus();
          });
        })
        .catch((error) => {
          let title = "Oops!";
          let text =
            "Ocorreu um erro ao acessar o fluxo de caixa do cliente. Entre em contato com o setor de TI.";
          let variant = "danger";
          if (error.response.status === 404) {
            title = "Cliente sem acesso ao App Meu Futuro";
            text =
              "Este cliente não criou conta no App Meu Futuro. Verifique nossos links úteis e solicite que o cliente crie uma conta";
            variant = "warning";
          }
          this.toast({
            component: ToastificationContent,
            props: {
              title,
              text,
              icon: "AlertTriangleIcon",
              variant,
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCustomerFinancialDiagnosisToken() {
      this.loading = true;
      this.getFinancialDiagnosisToken({
        customer_token: this.customer.token,
        days: 7,
      })
        .then((response) => {
          setTimeout(() => {
            const link =
              this.financialDiagnosisUrl +
              "/autenticacao/" +
              response.data?.authentication_token +
              "?back-url=diagnosis/" +
              this.customer.token;
            setTimeout(() => {
              window.open(link, "_blank");
            });
          });
        })
        .catch((error) => {
          let title = "Oops!";
          let text =
            "Ocorreu um erro ao acessar o fluxo de caixa do cliente. Entre em contato com o setor de TI.";
          let variant = "danger";
          if (error.response.status === 404) {
            title = "Cliente sem acesso ao App Meu Futuro";
            text =
              "Este cliente não criou conta no App Meu Futuro. Verifique nossos links úteis e solicite que o cliente crie uma conta";
            variant = "warning";
          }
          this.toast({
            component: ToastificationContent,
            props: {
              title,
              text,
              icon: "AlertTriangleIcon",
              variant,
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    goToFinancialPlanning() {
      this.$router.push({
        name: "financial-planning",
        params: { id: this.customer?.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.clickable {
  cursor: pointer;
}

.disabled-card {
  cursor: not-allowed;
  opacity: 0.6;
}

.rounded-icon {
  border: var(--info) 2px solid;
  border-radius: 50%;
  padding: 15px;
}
</style>
